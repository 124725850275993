export default function Footer() {
    return (
        <footer className="py-8 bg-AWS_darkBlue shadow-[0_2px_6px_rgba(0,0,0,0.15)]">
        <div className="container mx-auto px-4 text-center text-white text-sm flex flex-col items-center gap-2">
            <div>
            &copy; {new Date().getFullYear()} Alex Web Solutions. Tous droits réservés.
            </div>

            <div className="flex flex-wrap justify-center gap-4 text-xs text-gray-400">
            <a href="/mentions-legales" className="hover:underline">
                Mentions légales
            </a>
            <a href="/politique-confidentialite" className="hover:underline">
                Politique de confidentialité
            </a>
            <button
                onClick={() => {
                localStorage.removeItem("cookieConsent");
                location.reload();
                }}
                className="hover:underline text-blue-400"
            >
                Gérer les cookies
            </button>
            </div>
        </div>
        </footer>
    );
}