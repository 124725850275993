//src/components/EssentialComponents.tsx
import { motion } from "framer-motion";
import Link from "next/link";

export default function CallToAction() {
    return (
        <section id="contact" className="py-16 bg-gradient-to-br from-AWS_blue to-AWS_grey text-white">
            <div className="container mx-auto px-4 text-center">
                <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-4xl font-arimo font-extrabold  mb-4"
                >
                Un projet en tête ? Parlons-en !
                </motion.h2>
                <motion.p
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="mb-8 text-lg"
                >
                Contactez-nous dès aujourd’hui pour un devis gratuit et personnalisé.
                </motion.p>
                <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                whileInView={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                >
                <Link
                    href="mailto:contact@alexwebsolutions.com"
                    className="px-8 py-3 bg-AWS_darkBlue text-white font-semibold rounded-full hover:bg-AWS_blue transition"
                >
                    Contactez-nous
                </Link>
                </motion.div>
                <div className="mt-8">
                <p className="text-lg">
                    Téléphone : <a href="tel:0635235721" className="underline">06 35 23 57 21</a>
                </p>
                <p className="text-lg">
                    Email : <a href="mailto:devis@alexwebsolutions.com" className="underline">devis@alexwebsolutions.com</a>
                </p>
                </div>
            </div>
        </section>
    );
}

export function WhiteSpace() {
    return (
        <div className="h-10 bg-white"></div>
    );
}