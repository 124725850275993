//src/app/page.tsx
"use client";

import React from "react";
import { motion } from "framer-motion";
import Image from "next/image";
import Footer from "@/components/Footer";
import CallToAction, { WhiteSpace } from "@/components/EssentialComponents";
import CountUp from "react-countup";

export const metadata = {
  title: "Création de Site Internet Pro & Abordable | Alex Web Solutions",
  description:
    "Sites vitrines modernes pour PME, indépendants, assos et particuliers. Alex Web Solutions crée votre site sur-mesure, rapide et optimisé SEO.",
  robots: "index, follow",
  alternates: {
    canonical: "https://www.alexwebsolutions.com/",
  },
};

export default function Home() {


  const realisations = [
    {
      id: 1,
      url: "https://fauna-harmonia.com/",
      name: "Fauna Harmonia",
      desc: "Site vitrine d’ostheopathie animalière",
    },
    {
      id: 2,
      url: "https://bdeparis.esiea.fr/",
      name: "Bureau des Etudiants de l’ESIEA Paris",
      desc: "Site vitrine du bureau des étudiants de l’ESIEA 2023",
    },
    {
      id: 3,
      url: "",
      name: "Lensify",
      desc: "Carte de visite et identité visuelle",
    },
  ];

  return (
    <div>
      {/* Hero Section */}
      <section className="min-h-screen flex flex-col justify-center items-center text-center relative overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.2 }}
          transition={{ duration: 1 }}
          className="absolute inset-0 bg-gradient-to-br from-AWS_blue to-AWS_grey min-h-screen"
        />
        <div className="relative z-10 max-w-4xl px-4">
          <motion.h1
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            className="text-5xl md:text-8xl font-arimo font-bold mb-4"
          >
            Donnez vie à vos projets digitaux
          </motion.h1>
          <motion.p
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, delay: 0.3 }}
            className="text-xl md:text-2xl mb-8 text-gray-300"
          >
            Conception de sites web, identité visuelle et communication digitale
            pour booster votre image.
          </motion.p>
          <motion.a
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            href="/contact"
            aria-label="Demandez un devis personnalisé"
            className="inline-block bg-AWS_blue hover:bg-AWS_darkBlue text-lightText font-arimo font-semibold py-3 px-8 rounded-full transition"
          >
            Demandez un devis
          </motion.a>
        </div>
      </section>
      <section className="py-16 bg-AWS_black text-white">

      </section>
              
      {/* Siteweb Section */}
      <section className="py-16 bg-gradient-to-br from-AWS_black to-AWS_blue text-white">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-arimo text-center mb-4"
          >
            Pourquoi un Site Internet ?
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center text-gray-300 mb-12 max-w-2xl mx-auto"
          >
            Un site web performant est bien plus qu&apos;une vitrine digitale : c&apos;est un
            levier de croissance, de crédibilité et d&apos;engagement client.
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
            {/* Stat 1 - Visibilité Optimale */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="bg-gray-900 rounded-xl p-6 shadow-lg flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                className="text-6xl font-bold bg-AWS_blue text-transparent bg-clip-text"
              >
                <CountUp
                  start={0}
                  end={88}
                  duration={10}
                  suffix="%"
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </motion.div>
              <h3 className="text-2xl font-arimo mt-4">Visibilité Optimale</h3>
              <p className="text-center text-gray-400 mt-2">
                88% des consommateurs se tournent vers internet pour trouver des
                produits ou services.
              </p>
            </motion.div>

            {/* Stat 2 - Crédibilité Renforcée */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="bg-gray-900 rounded-xl p-6 shadow-lg flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                className="text-6xl font-bold bg-AWS_blue text-transparent bg-clip-text"
              >
                <CountUp
                  start={0}
                  end={75}
                  duration={10}
                  suffix="%"
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </motion.div>
              <h3 className="text-2xl font-arimo mt-4">Crédibilité Renforcée</h3>
              <p className="text-center text-gray-400 mt-2">
                75% des internautes jugent la crédibilité d&apos;une entreprise par la
                qualité de son site web.
              </p>
            </motion.div>

            {/* Stat 3 - Augmentation du CA */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.6 }}
              className="bg-gray-900 rounded-xl p-6 shadow-lg flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                className="text-6xl font-bold bg-AWS_blue text-transparent bg-clip-text"
              >
                <CountUp
                  start={0}
                  end={30}
                  duration={10}
                  suffix="%"
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </motion.div>
              <h3 className="text-2xl font-arimo mt-4">Augmentation du CA</h3>
              <p className="text-center text-gray-400 mt-2">
                Une hausse moyenne de 30% du chiffre d&apos;affaires grâce à un site
                performant.
              </p>
            </motion.div>

            {/* Stat 4 - Client connecté en direct */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.8 }}
              className="bg-gray-900 rounded-xl p-6 shadow-lg flex flex-col items-center"
            >
              <motion.div
                initial={{ scale: 0.8 }}
                animate={{
                  scale: 1,
                  x: [-1, -3, 3, 1],
                  y: [-1, 3, -3, 1],
                }}
                transition={{
                  duration: 1,
                  x: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 0.5,
                    ease: "easeInOut",
                  },
                }}
                className="text-6xl font-bold bg-AWS_blue text-transparent bg-clip-text"
              >
                <CountUp
                  start={0}
                  end={1}
                  duration={30}
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              </motion.div>
              <h3 className="text-2xl font-arimo mt-4">
                Utilisateur sur la bonne voie
              </h3>
              <p className="text-center text-gray-400 mt-2">
                <span className="text-blue-300 font-bold">Ça c&apos;est vous
                </span>,
                et vous êtes sur la bonne voie pour rejoindre notre communauté de clients satisfaits.
              </p>
            </motion.div>
          </div>
        </div>
      </section>
      

      {/* Réalisations Section */}
      <section className="py-16 bg-AWS_black">
        <div className="container mx-auto px-4">
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-arimo text-center mb-4"
          >
            Nos Réalisations
          </motion.h2>
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-center text-gray-400 mb-12 max-w-2xl mx-auto"
          >
            Découvrez quelques projets qui témoignent de notre expertise et de
            notre créativité.
          </motion.p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {realisations.map(({ id, url, name, desc }) => (
              <motion.div
                key={id}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: id * 0.2 }}
                className="overflow-hidden rounded-xl shadow-lg bg-gray-900 group"
              >
                {url ? (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={`Voir le site réalisé ${id}`}
                  >
                    <div className="relative w-full h-60">
                      <Image
                        src={`/realisation${id}.png`}
                        alt={`Réalisation ${id}`}
                        layout="fill"
                        objectFit="cover"
                      />
                      <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <h3 className="text-lg font-arimo font-semibold text-white">
                          {name}
                        </h3>
                        <p className="text-white text-sm mt-1">{desc}</p>
                      </div>
                    </div>
                  </a>
                ) : (
                  <div className="relative w-full h-60">
                    <Image
                      src={`/realisation${id}.png`}
                      alt={`Réalisation ${id}`}
                      layout="fill"
                      objectFit="cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 p-4 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <h3 className="text-lg font-arimo font-semibold text-white">
                        {name}
                      </h3>
                      <p className="text-white text-sm mt-1">{desc}</p>
                    </div>
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>
      <WhiteSpace />
      <CallToAction />
      <Footer />
    </div>
  );
}
